/**
 * Sticky header and main nav, currently
 * we're using it only at homepage
 */
(function () {
	var StickyHeader = {
		lastScrollTop: 0,
		headerHeight: 0,
		delaySticky: false,

		init: function () {
			this.body = document.body;
			this.headerWrapper = document.querySelector('.header');
			this.headerHeight = this.headerWrapper.getBoundingClientRect().height;
			this.delaySticky = document.querySelector('.page-id-408') || document.querySelector('.page-id-164') ? true : false;

			// this.handleDelay();
			this.initEvents();
		},

		initEvents: function () {
			this.handleScroll = this.handleScroll.bind(this);
			this.handleDelay = this.handleDelay.bind(this);

			window.addEventListener('scroll', () => {
				this.handleDelay();

				if(!this.delaySticky) {
					this.handleScroll();
				}
			}, { passive: true });
		},

		handleScroll: function () {
			if(document.querySelector('.header--menu-opened')) {
				return;
			}

			var currentPosition = window.scrollY;
			var isScrollingDown =
				currentPosition > this.headerHeight && currentPosition > this.lastScrollTop;

			this.headerWrapper.classList.toggle('header--sticky-hide', isScrollingDown);

			this.lastScrollTop = currentPosition;

			var header = document.getElementById('header');
			var sections = document.querySelectorAll('.entry-content > .wp-block-group:not(.wp-block-group > *)');

			sections.forEach(function(section) {
				var hasGradientChild = Array.from(section.children).some(function(child) {
					return child.classList.contains('liner-gradinet-grey-white');
				});
				if (hasGradientChild) {
					header.style.backgroundColor = 'pink';
					return; // Exit early if gradient child found
				}

				var rect = section.getBoundingClientRect();
				if ((rect.top >= 0 && rect.top <= 50) || (rect.bottom >= 0 && rect.bottom <= window.innerHeight)) {
					var computedStyle = window.getComputedStyle(section);
					var backgroundColor = computedStyle.backgroundColor;
					console.log(backgroundColor);
					if (backgroundColor === 'rgba(0, 0, 0, 0)') {
							backgroundColor = 'rgb(255, 255, 255)'; // Set background color to white
					}
					if (header.style.backgroundColor !== backgroundColor) {
							header.style.backgroundColor = backgroundColor;
					}
			}
			});
		},

		handleDelay: function() {
			if(document.querySelector('.page-id-164')) {
				const el = document.querySelector('.leistung-sticky-section-parent');

				const elHeight = el.offsetHeight;
				const currentPosition = window.scrollY;

				this.delaySticky = elHeight > currentPosition;
			}

			if(document.querySelector('.page-id-408')) {
				const el = document.querySelector('.translated-logo-hero-section');
				const elContent = document.querySelector('.translated-logo-hero-section__sticky-content');

				const elHeight = el.offsetHeight;
				const contentHeight = elContent.offsetHeight;
				const currentPosition = window.scrollY;

				this.delaySticky = elHeight - contentHeight > currentPosition;
			}

		}
	};

	StickyHeader.init();
})();